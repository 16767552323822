import React, { Suspense, lazy } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";

import Routes from "constants/routes";
import useUser from "hooks/useUser";
import GlobalStyles from "./global-styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LoadSpinner } from "components";
import { DefaultLayout } from "layouts";

const LoginPage = lazy(() => import("pages/LoginPage"));

const Settlement = lazy(() => import("pages/Settlement"));

const Errors = lazy(() => import("pages/Errors"));

const DistributeHistory = lazy(() => import("pages/DistributeHistory"));
const ListBuyCoin = lazy(() => import("pages/ListBuyCoin"));
const SellCoinRegistration = lazy(() => import("pages/SellCoinRegistration"));
const Statistic = lazy(() => import("pages/Statistic"));

const UserByCompany = lazy(() => import("pages/UserByCompany"));
const DownloadExcel = lazy(() => import("pages/DownloadExcel"));
const BankAccount = lazy(() => import("pages/BankAccount"));

const RefundHistory = lazy(() => import("pages/RefundHistory"));
const DownloadExcelRefund = lazy(() => import("pages/DownloadExcelRefund"));
const BankAccountRefund = lazy(() => import("pages/BankAccountRefund"));
const NewMember = lazy(() => import("pages/NewMember"));
const OldMember = lazy(() => import("pages/OldMember"));
const PendingMember = lazy(() => import("pages/PendingMember"));
const MemberDetail = lazy(() => import("pages/MemberDetail"));

const renderLoader = () => <LoadSpinner isOverlay={false} />;

const AppWrapper = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    .ant-layout-content {
      background-color: ${(props) => props.theme.innerContBg};
    }
  }
  .ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0;
    line-height: inherit;
    z-index: 99;
    position: sticky;
    top: 0;
  }
`;

declare global {
  interface Window {
    klaytn: any;
  }
}

function App() {
  const { isAuthenticated } = useUser();

  function PrivateRoute({ component: TargetPage, ...rest }) {
    // console.log("isAuthenticated", isAuthenticated)
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  return (
    <AppWrapper>
      {isAuthenticated === false ? (
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Redirect exact from="/" to={Routes.LOGIN} />
            <Route path={Routes.LOGIN} component={LoginPage} />
          </Suspense>
        </Switch>
      ) : (
        <DefaultLayout
          // pageHeading={t("STATISCAL_HEADING")}
          boardContent={
            <Switch>
              <Suspense fallback={renderLoader()}>
                {/* <Route exact path={Routes.LOGIN} component={LoginPage} /> */}
                {/* { isAuthenticated === false &&
              <Redirect exact from="/" to={Routes.LOGIN} />
            } */}

                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect exact from="/" to={Routes.ADMIN_LIST_BUY_COIN} />
                  )}
                />

                <Route path={Routes.LOGIN} component={LoginPage} />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SETTLEMENT}
                  component={Settlement}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_ERRORS}
                  component={Errors}
                />

                {/* <PrivateRoute
                  exact
                  path={Routes.ADMIN_DISTRIBUTE_HISTORY}
                  component={DistributeHistory}
                /> */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_BUY_COIN}
                  component={ListBuyCoin}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_USER_BY_COMPANY}
                  component={UserByCompany}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SELL_COIN_REGISTRATION}
                  component={SellCoinRegistration}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_STATISTIC}
                  component={Statistic}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DEPOSIT_HISTORY}
                  component={DownloadExcel}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_BANK_ACCOUNT}
                  component={BankAccount}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_REFUND_HISTORY}
                  component={RefundHistory}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DOWNLOAD_REFUND}
                  component={DownloadExcelRefund}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_BANK_ACCOUNT_REFUND}
                  component={BankAccountRefund}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_NEW_MEMBER}
                  component={NewMember}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_OLD_MEMBER}
                  component={OldMember}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_PENDING_MEMBER}
                  component={PendingMember}
                />
                <PrivateRoute
                  exact
                  path={`${Routes.ADMIN_MEMBER_DETAIL}/:id`}
                  component={MemberDetail}
                />
                {/* <PrivateRoute exact path={Routes.TEST} component={Test} /> */}
              </Suspense>
            </Switch>
          }
        />
      )}
      <GlobalStyles />
      <CssBaseline />
    </AppWrapper>
  );
}

export default App;
